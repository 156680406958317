var render = function render(){var _vm=this,_c=_vm._self._c;return _c('timeline-entry-item',{staticClass:"system-note",class:{
    target: _vm.isTargetNote,
    'pr-0': _vm.shouldShowDescriptionVersion,
  },attrs:{"id":_vm.noteAnchorId}},[_c('div',{staticClass:"gl-relative gl-float-left gl-flex gl-items-center gl-justify-center gl-rounded-full",class:[
      _vm.getIconColor,
      {
        'system-note-icon -gl-mt-1 gl-ml-2 gl-h-6 gl-w-6': _vm.isAllowedIcon,
        'system-note-dot -gl-top-1 gl-ml-4 gl-mt-3 gl-h-3 gl-w-3 gl-border-2 gl-border-solid gl-border-subtle gl-bg-gray-900':
          !_vm.isAllowedIcon,
      },
    ]},[(_vm.isAllowedIcon)?_c('gl-icon',{attrs:{"size":14,"name":_vm.note.systemNoteIconName}}):_vm._e()],1),_vm._v(" "),_c('div',{staticClass:"gl-ml-7"},[_c('div',{staticClass:"gl-flex gl-items-start gl-justify-between"},[_c('note-header',{attrs:{"author":_vm.note.author,"created-at":_vm.note.createdAt,"note-id":_vm.noteId,"is-system-note":true},scopedSlots:_vm._u([(_vm.canSeeDescriptionVersion)?{key:"extra-controls",fn:function(){return [_vm._v("\n          ·\n          "),(_vm.canSeeDescriptionVersion)?_c('gl-button',{staticClass:"gl-align-text-bottom !gl-text-sm",attrs:{"variant":"link","icon":_vm.descriptionVersionToggleIcon,"data-testid":"compare-btn"},on:{"click":_vm.toggleDescriptionVersion}},[_vm._v(_vm._s(_vm.__('Compare with previous version')))]):_vm._e()]},proxy:true}:null],null,true)},[_c('span',{directives:[{name:"safe-html",rawName:"v-safe-html",value:(_vm.actionTextHtml),expression:"actionTextHtml"}],ref:"gfm-content",staticClass:"gl-break-anywhere"})])],1),_vm._v(" "),_c('div',{staticClass:"note-body gl-pb-3 gl-pl-3"},[(_vm.shouldShowDescriptionVersion)?_c('div',{staticClass:"gl-relative !gl-pt-3"},[(_vm.isLoadingDescriptionVersion)?_c('pre',{staticClass:"loading-state"},[_vm._v("          "),_c('gl-skeleton-loader'),_vm._v("\n        ")],1):_c('pre',{directives:[{name:"safe-html",rawName:"v-safe-html",value:(_vm.descriptionVersion),expression:"descriptionVersion"}],staticClass:"gl-mt-3 gl-whitespace-pre-wrap gl-pr-7",attrs:{"data-testid":"description-version-diff"}}),_vm._v(" "),(_vm.displayDeleteButton)?_c('gl-button',{directives:[{name:"gl-tooltip",rawName:"v-gl-tooltip"}],staticClass:"gl-absolute",class:_vm.deleteButtonClasses,attrs:{"title":_vm.$options.i18n.deleteButtonLabel,"aria-label":_vm.$options.i18n.deleteButtonLabel,"variant":"default","category":"tertiary","icon":"remove","data-testid":"delete-description-version-button"},on:{"click":_vm.deleteDescriptionVersion}}):_vm._e()],1):_vm._e()])])])
}
var staticRenderFns = []

export { render, staticRenderFns }